import Link from 'next/link';
import { Button, Col, Container } from 'react-bootstrap';
import { FaCompass, FaHiking, FaMoneyBillWave, FaSearch } from 'react-icons/fa';
import { FaHandsClapping } from 'react-icons/fa6';
import { MdOutlineStar } from 'react-icons/md';
import styles from './WhySkyhook.module.scss';

export default function WhySkyhook() {
  return (
    <section className="position-relative bg-light mt-4">
      <Container className="py-5">
        <Col xs={12} lg={7} className="text-center mx-auto py-2">
          <h2 className="fw-bold">Why Skyhook?</h2>
          <p className="lead">
            Join over 27,000 Skyhook adventurers who've used our platform to
            book directly with our vetted local guides, at local prices (we
            never markup).
          </p>
        </Col>
        <Col xs={12} className="text-center mx-auto py-5">
          <div className={styles.tiles}>
            <div>
              <FaCompass className="mb-3 text-primary" size={40} />
              <h5 className=" text-dark">Expert Local Guides</h5>
              <p>Experienced local guides, handpicked by us.</p>
            </div>
            <div>
              <FaMoneyBillWave size={40} className="mb-3 text-primary" />
              <h5 className=" text-dark">Best Prices</h5>
              <p>Never pay a markup on the local guide's price.</p>
            </div>
            <div>
              <FaHiking size={40} className="mb-3 text-primary" />

              <h5 className=" text-dark"> Exclusive Club</h5>
              <p>
                Earn <Link href={'/club'}>loyalty rewards</Link> every time you
                travel.
              </p>
            </div>
            <div>
              <FaHandsClapping size={40} className="mb-3 text-primary" />
              <h5 className=" text-dark">Great Social Vibes</h5>
              <p>Small group tours provide a richer experience.</p>
            </div>
            <div>
              <div
                className="d-flex justify-content-center mb-4"
                style={{
                  height: '33px',
                }}
              >
                {[1, 2, 3, 4, 5].map((i) => (
                  <MdOutlineStar
                    size={30}
                    key={i}
                    className="text-primary mt-2"
                  />
                ))}
              </div>

              <h5 className=" text-dark">Stellar Feedback</h5>
              <p>
                Over{' '}
                <Link className="text-primary" href={'/reviews'}>
                  2,800 reviews
                </Link>
                , average of 4.9/5 stars.
              </p>
            </div>
          </div>
        </Col>
        <Col xs={12} className="text-center mx-auto py-3">
          <Link href="/trips">
            <Button variant="outline-primary">
              <FaSearch />
              <span className="ms-2">Find a trip</span>
            </Button>
          </Link>
        </Col>
      </Container>
    </section>
  );
}
